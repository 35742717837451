<template>
  <div v-if="isLocale === 'true'" class="language-set">
    <el-select v-model="currentLang" @change="handleClickLocale" class="language-selector" size="small">
      <template slot="prefix">
        <i
          class="iconfont icon-locale"
          style="cursor: pointer; font-size: 16px; color: rgba(48, 100, 143, 1); line-height: 42px;"
        ></i>
      </template>
      <el-option label="简体中文" value="zh" class="option-text"></el-option>
      <el-option label="English" value="en" class="option-text"></el-option>
      <!-- <el-option label="Deutsch" value="de"></el-option> -->
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentLang: this.getLanguageName(),
    };
  },
  computed: {
    isLocale() {
      return sessionStorage.getItem("isLocale") || "false";
    },
  },
  created() {
    sessionStorage.setItem("isLocale", "true");
  },
  methods: {
    getLanguageName() {
      const langCode = localStorage.getItem("lang");
      switch (langCode) {
        case "en":
          return "English";
        case "zh":
          return "简体中文";
        // 增加更多语言
        default:
          return "简体中文";  
      }
    },
    handleClickLocale(command) {
      localStorage.setItem("lang", command);
      this.currentLanguage = this.getLanguageName();
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.language-selector {
  width: 157px;
  height: 41px;
}

.option-text {
  color: rgba(0, 0, 0, 0.65); 
}
</style>

<style lang="scss">
.language-selector {
  .el-input__inner{
    height: 41px;
  }
  /* 下面设置右侧按钮居中 */
  .el-input__icon {
      line-height: 41px;
  }
  .el-input__suffix-inner {
      display: inline-block;
  }
}
</style>
